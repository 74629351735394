.cantJoinScreen {
  height: 100%;
  width: 100%;
  background-color: var(--background);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image {
  width: 180px;
  height: auto;
}

.title {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  padding-top: 32px;
  text-align: center;
}

.reason {
  color: var(--text-color);
  font-size: 1.4rem;
  font-weight: 400;
  padding-top: 32px;
  text-align: center;
}

.goBack {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  background-color: var(--primary);
  color: var(--primary-text);
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 32px;
  height: 40px;
  margin-top: 32px;
}
