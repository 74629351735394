.finishedScreen {
  height: 100%;
  width: 100%;
  background-color: var(--background);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 16px;
}

.image {
  height: 160px;
  width: auto;
  opacity: 0;
  animation: fadeIn 1s 2s linear forwards;
}

.title {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  padding-top: 32px;
  text-align: center;
  opacity: 0;
  animation: fadeIn 1s 0ms linear forwards;
}

.winner {
  color: var(--winner);
  font-size: 2rem;
  font-weight: 400;
  margin-top: 16px;
  background-color: #000000;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 288px;
  opacity: 0;
  animation: fadeIn 1s 1s linear forwards;

  [role="img"] {
    margin-right: 16px;
  }
}

.congrats {
  margin-top: 16px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  opacity: 0;
  animation: fadeIn 1s 2s linear forwards;
  width: 288px;
}

.playAgainBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  background-color: var(--primary);
  color: var(--primary-text);
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 32px;
  height: 60px;
  margin-top: 64px;
  width: 288px;
  opacity: 0;
  animation: fadeIn 1s 2s linear forwards;

  &:disabled {
    cursor: wait;
  }
}

.exitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  color: var(--text-color);
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 32px;
  height: 40px;
  margin-top: 16px;
  width: 288px;
  opacity: 0;
  animation: fadeIn 1s 2s linear forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
