.waitingScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  border-radius: var(--edges-radius, 8px);
  background: var(--background-lighter);
  padding: 32px;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 24px;
}

.playerContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 16px;
  gap: 8px;

  @media (min-width: 400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.playerItem {
  font-size: 0.8rem;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-clamp: 1;

  .status {
    width: 8px;
    height: 8px;
    background-color: var(--offline);
    border-radius: 4px;
    margin-right: 4px;

    &.online {
      background-color: var(--online);
    }
  }
}

.inviteUrlCopyBtn {
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  background-color: var(--primary);
  color: var(--primary-text);
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 16px;
  height: 40px;
  width: 100%;
  margin-top: 16px;
}
