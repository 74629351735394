.loadingScreen {
  height: 100%;
  width: 100%;
  background-color: var(--background);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  padding-top: 32px;
  text-align: center;
}

// Extracted from https://projects.lukehaas.me/css-loaders/
.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.spinner {
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.1);
  border-right: 1.1em solid rgba(0, 0, 0, 0.1);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.1);
  border-left: 1.1em solid var(--primary);
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
