.gameCard {
  position: relative;

  background: #ffffff;
  width: 180px;
  height: 240px;
  border-radius: 8px;
  font-weight: bold;

  color: var(--color);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.topLeftIndicator {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 20px;
}

.centerIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 64px;
}
