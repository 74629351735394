.playingScreen {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.players {
  padding: 24px 16px 24px;
  display: flex;
  overflow-x: auto;

  & > * + * {
    margin-left: 16px;
  }

  &::after {
    content: "";
    display: block;
    min-width: 16px;
  }
}

.cardsInPlay {
  position: relative;
  padding: 16px;
  flex: 1;
  height: 100%;

  .cardContainer {
    position: absolute;
    top: calc(50% + 12px);
    left: calc(50% - 24px);
    transform: translate(-50%, -50%);
    opacity: 1;

    &:not(:last-child) {
      animation: moveCardToDeck 500ms ease-in-out forwards;

      &.exit {
        animation: exitCard 800ms 200ms ease-in-out forwards;
      }
    }

    &:last-child {
      animation: enterCard 500ms ease-in-out forwards;

      &.exit {
        animation: moveCardToDeck 200ms ease-in-out forwards,
          exitCard 800ms 200ms ease-in-out forwards;
      }
    }
  }
}

@keyframes enterCard {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) translate(48px, -100%) rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) translate(48px, -24px) rotate(10deg);
  }
}

@keyframes moveCardToDeck {
  0% {
    transform: translate(-50%, -50%) translate(48px, -24px) rotate(10deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes exitCard {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    opacity: 0;
    top: -25%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

button.playCardBtn {
  background-color: var(--primary);
  color: var(--primary-text);
  padding: 16px 24px;
  height: 64px;
  border-radius: 8px;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 288px;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }

  [role="img"] {
    margin-right: 16px;
  }
}

button.pairBtn {
  background-color: var(--offline);
  color: var(--primary-text);
  padding: 16px 24px;
  width: 160px;
  border-radius: 8px;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  margin-bottom: 8px;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }

  [role="img"] {
    margin-right: 16px;
  }

  // .text {
  //   writing-mode: vertical-rl;
  //   text-orientation: upright;
  //   letter-spacing: -4px;
  // }
}
