.hostControls {
  position: relative;

  border-top: 4px solid var(--primary);
  padding: 24px 32px 16px;
  background-color: var(--background-lighter);
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.22);
  height: 80px;

  display: flex;
  justify-content: flex-end;

  border-radius: var(--edges-radius) var(--edges-radius) 0 0;
}

.tag {
  position: absolute;
  top: 0;
  left: 32px;
  padding: 0 4px 2px;
  background-color: var(--primary);
  border-radius: 0 0 4px 4px;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--primary-text);
  font-size: 0.6em;
}

.startGameBtn {
  min-width: 128px;
  margin: 0;
  margin-top: -8px;
  border: none;
  border-radius: 4px;
  background-color: rgb(46, 170, 94);
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;
  cursor: pointer;

  transition-duration: 180ms;
  transition-timing-function: ease-in;
  transition-property: background-color, opacity;

  &:disabled {
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  font-size: 0.8rem;
  margin-right: auto;

  &.online .ball {
    background-color: var(--online);
  }

  .ball {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: var(--offline);
    margin-right: 6px;
  }
}
