:root {
  --background-lighter: #ffffff;
  --background: #f0f0f0;
  --background-darker: #e0e0e0;
  --text-color: #333;
  --primary: rgb(0, 112, 218);
  --primary-text: #fff;
  --edges-radius: ;
  --online: rgb(52, 209, 118);
  --offline: rgb(180, 55, 55);
  --winner: #ece236;
  --winner-text: #fff;
}

@media screen and (min-width: 1024px) {
  :root {
    --edges-radius: 8px;
  }
}

html,
body,
#root {
  height: 100%;
  background-color: var(--background);
  color: var(--text-color);
}

#root {
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
}

button,
a {
  transition: opacity 180ms ease-in;

  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

html,
body,
input,
button,
textarea {
  font-family: "Roboto", sans-serif;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
